import { useParams } from 'react-router-dom';

import { useEffect, useState, useRef } from 'react';
import { setDoc, doc, updateDoc } from "firebase/firestore";
import { db } from './firebase';
import { functions } from './firebase';
import { httpsCallable } from 'firebase/functions';
import { SendHorizontal } from 'lucide-react';
import { Gift } from 'lucide-react';
import { MessageCircleMore } from 'lucide-react';
import logo from './footer-logo.png';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import { Comment } from 'react-loader-spinner';

function App() {
  const { carInfo } = useParams();

  const [Chat, setChat] = useState([]);
  const [DocId, setDocId] = useState('');
  const [ChatTriggered, setChatTriggered] = useState(false);
  const [CarDetails, setCarDetails] = useState(null);
  const [ShowAppointmentForm, setShowAppointmentForm] = useState(false);
  const [AppointmentBooked, setAppointmentBooked] = useState(false);
  const [AppointmentDate, setAppointmentDate] = useState(new Date());
  const [IsLoading, setIsLoading] = useState(false);

  const messagesEndRef = useRef(null);
  const messageInputRef = useRef(null);

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  }

  const createNewChat = async () => {
    const decodedCarInfo = decodeURI(carInfo);
    const tmp = JSON.parse(decodedCarInfo);
    console.log(tmp);
    const timeStamp = Date.now();
    const chatRef = doc(db, 'dealers', 'ZJJPptWt8MgrAkCJinJGGXPc8Z22', 'chats', timeStamp.toString());
    const messages = [
      {
        role: "user", content: `I'm interested in: ${decodedCarInfo}`
      }
    ];
    setDoc(chatRef, { 'chat': messages });


    const completionText = await askCreel(messages);

    const response = {
      'role': 'assistant',
      'content': completionText
    }

    messages.push(response);

    const docRef = doc(db, 'dealers', 'ZJJPptWt8MgrAkCJinJGGXPc8Z22', 'chats', timeStamp.toString());
    updateDoc(docRef, { 'chat': messages });

    setDocId(timeStamp.toString());
    setChat(messages);
    setCarDetails({
      name: tmp.Name,
      mileage: tmp.Mileage,
      price: tmp.Price,
      imageUrl: decodeURIComponent(tmp.Image)
    });
  }

  const askCreel = (chat) => {
    return new Promise((resolve, reject) => {
      const callCreel = httpsCallable(functions, 'callCreel');
      callCreel({
        messages: chat,
        workingHours: 'Mon-Fri: 9-5',
        phoneNumber: '(541) 262-5901',
        address: '468 NW Garden Valley Blvd, Roseburg, OR 97470',
        financing: 'Available',
        other: 'Only bank financing, no in-house financing, we accept trade ins, no buy here pay here, extended warranties, best customer support in the region',
      })
        .then((response) => {
          const creelResponse = response.data.completionText;
          if (creelResponse.includes("*")) {
            console.log('*');
            setShowAppointmentForm(true);
            const cleanMessage = creelResponse.replace("*", "");
            resolve(cleanMessage);
          }
          resolve(creelResponse);
        })
        .catch((err) => console.log(err));
    });
  }

  const handleNewMessage = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const newMessageContent = e.target.message.value;
    const newMessage = {
      'role': 'user',
      'content': newMessageContent
    }
    const newChat = [...Chat];
    newChat.push(newMessage);
    setChat(newChat);

    const docRef = doc(db, 'dealers', 'ZJJPptWt8MgrAkCJinJGGXPc8Z22', 'chats', DocId);
    updateDoc(docRef, { 'chat': newChat });

    e.target.message.value = '';

    setTimeout(() => {
      handleCreelResponse(newChat);
    }, 1000)
  }

  const handleCreelResponse = async (chat) => {
    const completionText = await askCreel(chat);

    const response = {
      'role': 'assistant',
      'content': completionText
    }

    const newChat = [...chat];
    newChat.push(response);
    setIsLoading(false);
    setChat(newChat);

    const docRef = doc(db, 'dealers', 'ZJJPptWt8MgrAkCJinJGGXPc8Z22', 'chats', DocId);
    updateDoc(docRef, { 'chat': newChat });
  }

  const setCarOfInterest = () => {
    const docRef = doc(db, 'dealers', 'ZJJPptWt8MgrAkCJinJGGXPc8Z22', 'chats', DocId);
    updateDoc(docRef, {
      carOfInterest: CarDetails.name,
      carImage: CarDetails.imageUrl,
    });
  }

  const handleBookAppointment = (e) => {
    e.preventDefault();
    const prospectName = e.target.prospectName.value;
    const prospectPhone = e.target.prospectPhone.value;
    e.target.prospectName.value = '';
    e.target.prospectPhone.value = '';
    const docRef = doc(db, 'dealers', 'ZJJPptWt8MgrAkCJinJGGXPc8Z22', 'chats', DocId);
    updateDoc(docRef, {
      appointmentDetails: {
        prospectName: prospectName,
        prospectPhone: prospectPhone,
        appointmentDate: AppointmentDate,
      }
    });
    setShowAppointmentForm(false);
    setAppointmentBooked(true);
    const newMessage = {
      'role': 'user',
      'content': 'Appointment booked',
    }
    const newChat = [...Chat];
    newChat.push(newMessage);
    setChat(newChat);
    updateDoc(docRef, { 'chat': newChat });

    handleCreelResponse(newChat);
  }

  const keepFocus = () => {
    messageInputRef.current.focus();
  }

  useEffect(() => {
    if (ChatTriggered) {
      createNewChat();
      console.log('Triggered');
    } else {
      console.log('Not triggered');
    }
  }, [ChatTriggered]);

  useEffect(() => {
    if (DocId) {
      setCarOfInterest();
    } else {
      console.log('Still loading...')
    }
  }, [DocId]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [Chat]);

  return (
    <div className='app-container'>
      {!ChatTriggered ?
        <div className='lead-hook'>
          <div className='badge'><Gift size={14} />New Offer!</div>
          <h1>Unbeatable deals. Get approved & drive today!</h1>
          <button id='btn-lead-hook' className='btn-lead-hook' onClick={() => setChatTriggered(true)}>Chat Now!</button>
        </div>
        :
        <>
          {Chat.length > 0 &&
            <div className='overlay'>
              <MessageCircleMore size={20} />
              Resume Chat
            </div>}
          <div className='chat-container'>
            {Chat.length <= 4 && <div className='powered-by'><img src={logo} width={10} />Powered by Creel</div>}
            <ul>
              <div style={{ flex: 1 }}></div>
              {Chat.map((message, index) => {
                if (index > 0) return (
                  <li
                    className={`message-container m-c-${message.role}`}
                    key={index}
                    ref={messagesEndRef}
                  >
                    <div className={`message-bubble m-b-${message.role}`}>
                      <p>{message.content}</p>
                    </div>
                  </li>
                );
              })}
              {IsLoading &&
                <li
                  className='message-container m-c-assistant'
                >
                  <div className='message-bubble m-b-assistant-loading'>
                    <Comment
                      visible={true}
                      width={48}
                      ariaLabel="comment-loading"
                      wrapperStyle={{ marginTop: 4 }}
                      wrapperClass="comment-wrapper"
                      color="#fff"
                      backgroundColor="transparent"
                    />
                  </div>
                </li>}
              {ShowAppointmentForm && !AppointmentBooked &&
                <form
                  className='appointment-form'
                  onSubmit={(e) => handleBookAppointment(e)}
                >
                  <DatePicker
                    showIcon
                    showTimeInput
                    dateFormat="MMMM, dd  •  h:mm a"
                    closeOnScroll={true}
                    selected={AppointmentDate}
                    onChange={(date) => setAppointmentDate(date)}
                    filterDate={isWeekday}
                  />
                  <input
                    name='prospectName'
                    placeholder='Your full name'
                    autoComplete='off'
                  />
                  <input
                    name='prospectPhone'
                    placeholder='Your phone number'
                    autoComplete='off'
                  />
                  <button>Book appointment</button>
                </form>
              }
            </ul>
          </div>
          <div className='message-input-wrapper'>
            <form className='message-input-container' onSubmit={(e) => handleNewMessage(e)}>
              <input
                autoFocus={true}
                className='message-input'
                name='message'
                autoComplete='off'
                ref={messageInputRef}
              />
              <button
                className={IsLoading ? 'send-button btn-disabled' : 'send-button'}
                disabled={IsLoading}
                onClick={() => { keepFocus() }}
              >
                <SendHorizontal />
              </button>
            </form>
          </div>
        </>
      }

    </div >
  );
}

export default App;